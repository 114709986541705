import { RouteConfig } from 'vue-router';
import { RouteMetaDefinition } from '@/router/meta';

// Meta data is an object that defines a route, you can check against meta data in RouteGuards.
const authedMeta: RouteMetaDefinition = {
  auth: true,
  layout: () => import('../../layouts/authenticatedLayout.vue')
};

const routes: RouteConfig[] = [
  {
    path: '/create-asset',
    alias: '/create-post',
    meta: authedMeta,
    components: {
      default: () => import('./module.vue')
    },
    children: [
      {
        name: 'create.root',
        path: '',
        meta: authedMeta,
        component: () => import('./views/index.vue')
      }
      /*{
        name: 'assetlibrary.category',
        path: '/asset-library/:categoryId',
        meta: authedMeta,
        component: () => import('./views/index.vue')
      },*/
    ]
  }
];

export default routes;
