import store from './store';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AssetListItem } from '@/models/assets/assetListItem';

const getDefaultState = () => {
  return {
    visible: false,
    selectedAsset: false
  };
};

@Module({ dynamic: true, store, name: 'AssetModalModule' })
export class AssetModalModule extends VuexModule {
  public visible: boolean = false;
  public selectedAsset: AssetListItem | null = null;

  @Mutation
  public setAssetModalVisible(visible: boolean): void {
    this.visible = visible;
  }

  @Mutation
  public setSelectedAsset(asset: AssetListItem | null): void {
    this.selectedAsset = asset;
  }

  @Mutation
  resetState() {
    Object.assign(this, getDefaultState());
  }
}
