import { RouteConfig } from 'vue-router';
import { RouteMetaDefinition } from '@/router/meta';

// Meta data is an object that defines a route, you can check against meta data in RouteGuards.
const authedMeta: RouteMetaDefinition = {
  auth: true,
  layout: () => import('../../layouts/authenticatedLayout.vue')
};

const routes: RouteConfig[] = [
  {
    path: '/home',
    meta: authedMeta,
    components: {
      default: () => import('./module.vue')
    },
    children: [
      {
        name: 'home.root',
        path: '',
        meta: authedMeta,
        component: () => import('./views/index.vue')
      }
    ]
  },
]

export default routes;