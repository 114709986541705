import store from './store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { PostsServiceClient } from '@/api/clients/postsServiceClient';
import { PostListItem } from '@/models/posts/postListItem';

const client = new PostsServiceClient();

@Module({
  namespaced: true,
  name: 'PostsModule',
  store,
  dynamic: true
})
export class PostsModule extends VuexModule {
  public upcomingPosts: PostListItem[] | null = null;

  @Action
  async deletePost(payload: {
    storeId: guid;
    postId?: guid;
    msoPostId?: guid;
  }) {
    const { storeId, postId, msoPostId } = payload;
    return await client.deletePost(storeId, postId, msoPostId);
  }

  @Action
  async unpublishPost(payload: {
    storeId: guid;
    postId?: guid;
    msoPostId?: guid;
    channels?: string[] | null | undefined;
  }): Promise<boolean> {
    const { storeId, postId, msoPostId, channels } = payload;
    return await client.unpublishPost(storeId, postId, msoPostId, channels);
  }

  @Action({ commit: 'setUpcomingPosts' })
  async getUpcomingPosts(): Promise<PostListItem[] | undefined> {
    return await client.getUpcomingPosts();
  }

  @Mutation
  setUpcomingPosts(upcomingPosts: PostListItem[]): void {
    this.upcomingPosts = upcomingPosts;
  }
}
