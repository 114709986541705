import { RouteConfig } from 'vue-router';
import { RouteMetaDefinition } from '@/router/meta';

// Meta data is an object that defines a route, you can check against meta data in RouteGuards.
const authedMeta: RouteMetaDefinition = {
  auth: true,
  layout: () => import('../../layouts/authenticatedLayout.vue')
};

const routes: RouteConfig[] = [
  {
    path: '/creative-centre',
    meta: authedMeta,
    components: {
      default: () => import('./module.vue')
    },
    children: [
      {
        name: 'creativecentre.root',
        path: '',
        meta: authedMeta,
        component: () => import('./views/index.vue')
      },
      {
        name: 'creativecentre.campaignCategory',
        path: '/creative-centre/:category',
        meta: authedMeta,
        component: () => import('./views/campaignCategory.vue')
      },
      {
        name: 'creativecentre.campaignDetail',
        path: '/creative-centre/:category/:id',
        meta: authedMeta,
        component: () => import('./views/campaignDetail.vue')
      }
    ]
  }
];

export default routes;
