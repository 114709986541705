export class CampaignsRequest {

  constructor(
    public startDateTime = '',
    public endDateTime = '',
    public category = '',
    public pagination = null
  ) {
    this.startDateTime = startDateTime;
    this.endDateTime = endDateTime;
    this.category = category;
    this.pagination = pagination
  }
}