import { Region } from '@/models/stores/regions';
import { AppApi } from '@/api/appApi';
import { HomeResponse } from '@/api/contracts/home/homeResponse';

export class HomeServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async getHomeEvents(
    region: Region,
    storeId?: guid,
    dateStr?: string // used to get single day. API does now support range if needed
  ): Promise<HomeResponse> {
    const state = region === Region.ALL ? 'national' : region;
    let url = `/api/mso/thisweek/${state}?`;
    if (storeId) url += `&storeId=${storeId}`;
    if (dateStr)
      url += `&fromDate=${dateStr} 00:00:00&toDate=${dateStr} 23:59:59`;
    const response = await this.get<HomeResponse>(url);

    return response.data as HomeResponse;
  }
}
