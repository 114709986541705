import { SocialChannel } from './../models/posts/socialChannels';
import { UserModule } from '@/store/userModule';
import { LocalStorageKeys, DummyStoreForAllStores } from '@/constants';
import store from './store';
import {
  Action,
  Module,
  Mutation,
  VuexModule,
  getModule
} from 'vuex-module-decorators';
import { StoresServiceClient } from '@/api/clients/storesServiceClient';
import { StoreDetails } from '@/models/settings/storeDetails';
import { UserStore } from '@/models/users/userStore';
import { Region } from '@/models/stores/regions';
import { Brand, BrandName } from '@/models/stores/brands';

const service = new StoresServiceClient();
const userModule = getModule(UserModule);

@Module({
  dynamic: true,
  store,
  name: 'StoresModule'
})
export class StoresModule extends VuexModule {
  public storeDetails: StoreDetails | null = null;
  public storeChannels: SocialChannel[] = [];
  public showAuthoriseSocialChannelsModal: boolean = false;

  @Action({ commit: 'setStoreDetails' })
  public async getStoreDetails(storeId: guid): Promise<StoreDetails> {
    const response = await service.getStore(storeId);
    return response.data;
  }

  @Action({ commit: 'setStoreChannels' })
  public async getStoreChannels(storeId: guid): Promise<SocialChannel[]> {
    return await service.getStoreChannels(storeId);
  }

  @Action({ commit: 'setStoreChannels' })
  public async updateSocialChannels(storeId: guid): Promise<SocialChannel[]> {
    return await service.updateSocialChannels(storeId);
  }

  @Action({ commit: 'setStoreDetails' })
  public async updateStoreDetails(store: StoreDetails): Promise<StoreDetails> {
    const response = await service.updateStore(store);
    return response.data;
  }

  @Mutation
  public changeStoreDetails({ key, value }: { key: string; value: string }) {
    if (this.storeDetails) {
      // @ts-ignore
      this.storeDetails[key] = value;
    }
  }

  @Mutation
  public setStoreChannels(channels: SocialChannel[]) {
    this.storeChannels = channels;
  }

  @Mutation
  public setStoreDetails(store: StoreDetails) {
    this.storeDetails = store;
  }

  @Mutation
  public toggleAuthoriseSocialChannelsModal(val: boolean) {
    this.showAuthoriseSocialChannelsModal = val;
  }

  /* --------------------------- */
  /*     MSO functions below     */
  /* --------------------------- */
  private _userStores: UserStore[] = [];
  public currentRegion = Region.ALL;
  public regionalStoreCount: Map<Region, number> = new Map();
  public isCurrentStoreSet: boolean = false;
  public currentBrand: Brand = {
    name: BrandName.IGA,
    key: null
  };
  public availableBrands: Brand[] = [];

  public get regions(): Region[] {
    return [...this.regionalStoreCount.keys()];
  }

  public get userStores(): UserStore[] {
    if (this._userStores.length === 1) {
      return this._userStores;
    } else {
      return [
        ...this._userStores,
        new DummyStoreForAllStores(this.currentRegion)
      ];
    }
  }

  // note: currently adds 'All stores' if length > 1
  public get userStoresInBrandAndRegion(): UserStore[] {
    const filteredStores = userModule.currentUser!.stores.filter(store => {
      const isBrandMatch = this.currentBrand.name === store.brand.name;
      let isRegionMatch = true;
      if (this.currentRegion !== Region.ALL) {
        isRegionMatch = this.currentRegion === store.state;
      }
      return isBrandMatch && isRegionMatch;
    });
    if (filteredStores.length === 1) {
      return filteredStores;
    }
    return [...filteredStores, new DummyStoreForAllStores(this.currentRegion)];
  }

  @Action
  public async getAllStoresChannels(storeIds: guid[]) {
    const result = await Promise.allSettled(
      storeIds.map(storeId =>
        this.context.dispatch('getStoreChannels', storeId)
      )
    );
    return result;
  }

  @Action({ commit: 'setStoreCount' })
  public async getRegions(): Promise<Map<Region, number>> {
    const data = await service.getStoreCount();
    const regionalStoreCount = new Map<Region, number>();
    data.region.forEach(e => regionalStoreCount.set(e.state, e.store_count));
    regionalStoreCount.set(Region.ALL, data.total);
    return regionalStoreCount;
  }

  @Action
  public async regionChanged(region: Region): Promise<void> {
    this.context.commit('setCurrentRegion', region);
    await this.context.dispatch('getUserStores');
  }

  @Action({ commit: 'setUserStores' })
  public async getUserStores(): Promise<UserStore[]> {
    const stores = await service.getStoresInRegion(this.currentRegion);
    return stores;
  }

  @Mutation
  public setCurrentRegion(region: Region) {
    this.currentRegion = region;
    localStorage.setItem(LocalStorageKeys.CurrentRegion, region);
  }

  @Mutation
  public setCurrentBrand(brand: Brand) {
    this.currentBrand = brand;
    localStorage.setItem(LocalStorageKeys.CurrentBrand, JSON.stringify(brand));
  }

  @Mutation
  public setAvailableBrands(brands: Brand[]) {
    this.availableBrands = brands;
    localStorage.setItem(
      LocalStorageKeys.AvailableBrands,
      JSON.stringify(brands)
    );
  }

  @Mutation
  public setUserStores(stores: UserStore[]) {
    this._userStores = stores;
    if (this.isCurrentStoreSet) return;

    if (stores.length === 1) {
      userModule.setCurrentStore(stores[0]);
    } else {
      userModule.setCurrentStore(
        new DummyStoreForAllStores(this.currentRegion)
      );
    }
  }

  @Mutation
  public setStoreCount(data: Map<Region, number>): void {
    this.regionalStoreCount = data;
  }

  @Mutation
  public reset(): void {
    this._userStores = [];
    this.currentRegion = Region.ALL;
    this.storeChannels = [];
    this.storeDetails = null;
    this.isCurrentStoreSet = false;
  }

  @Mutation
  public currentStoreSet(val: boolean): void {
    this.isCurrentStoreSet = val;
  }
}
