import { AppApi } from '@/api/appApi';
import {
  CampaignCategoriesResponse,
  CampaignDetailResponse,
  CampaignListItem,
  CampaignsResponse,
  CampaignSubCategoriesResponse
} from '@/api/contracts/campaigns';
import { CampaignsByCategoryRequest } from '@/models/campaigns/campaignsByCategoryRequest';
import { PostsResponse } from '@/api/contracts/posts/postsResponse';
import { CampaignAssetsResponse } from '@/api/contracts/campaigns/campaignAssetsResponse';
import { CampaignsRequest } from '@/models/campaigns/campaignsRequest';
import { CampaignAssetListParams } from '@/models/campaigns/campaignAssetListParams';
import { CampaignPostsParams } from '@/api/clients/campaignPostsParams';

export class CampaignServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async getCampaigns(
    params: CampaignsRequest
  ): Promise<CampaignsResponse> {
    const response = await this.get<CampaignsResponse>('api/campaigns', {
      params: {
        category: params.category,
        startDateTime: params.startDateTime,
        endDateTime: params.endDateTime
      }
    });

    return response.data;
  }

  public async getCampaignsByCategoryId(
    params: CampaignsByCategoryRequest
  ): Promise<CampaignSubCategoriesResponse> {
    const response = await this.get<CampaignSubCategoriesResponse>(
      `api/campaigns/categories/${params.categoryId}`,
      {
        params: {
          state: params.state
        }
      }
    );

    return response.data;
  }

  public async getCampaignAssets(
    params: CampaignAssetListParams
  ): Promise<CampaignAssetsResponse> {
    const response = await this.get<CampaignAssetsResponse>(
      'api/campaigns/assets',
      {
        params: {
          state: params.state,
          campaignId: params.campaignId,
          campaignCategoryId: params.campaignCategoryId,
          productTypeCategoryId: params.productTypeCategoryId,
          audienceCategoryId: params.audienceCategoryId,
          query: params.query,
          limit: params.pagination.limit,
          start: params.pagination.start || 0,
          sort: params.pagination.sort
        }
      }
    );

    return response.data;
  }

  public async getCampaignCenterCampaignList(
    userId: guid
  ): Promise<CampaignListItem[]> {
    const response = await this.get<CampaignListItem[]>(
      `campaign-center/${userId}/campaigns`
    );
    return response.data as CampaignListItem[];
  }

  public async getCampaignDetail(id: guid): Promise<CampaignDetailResponse> {
    const response = await this.get<CampaignDetailResponse>(
      `api/campaigns/${id}`
    );
    return response.data;
  }

  public async getCampaignCategories(): Promise<CampaignCategoriesResponse> {
    const response = await this.get<CampaignCategoriesResponse>(
      `/api/categories/campaigns`
    );
    return response.data;
  }

  public async getCampaignPosts(
    params: CampaignPostsParams
  ): Promise<PostsResponse> {
    const response = await this.get<PostsResponse>(
      `/api/campaigns/${params.id}/posts`,
      {
        params: {
          state: params.state
        }
      }
    );
    return response.data;
  }
}
