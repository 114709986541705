import { AppApi } from '@/api/appApi';
import { DemoItem } from '../contracts/demo';

export class DemoServiceClient extends AppApi {
  constructor() {
    // Not passing in the env values here.
    super(`/`);
  }

  public async getDemoContent(
    demoParam: guid
  ): Promise<DemoItem> {
    console.log(demoParam);
    const response = await this.get<DemoItem>(
      `mocks/demoContent.json`
    );
    return response.data;
  }
}
