// CreateModule is responsible for the create page flow, presets and the stage
// CreateAssetModule is responsible for asset information, asset stores, asset channels etc
// CreatePostModule is responsible for post information, post stores, post channels etc

import store from './store';
import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators';
import { PostsServiceClient } from '@/api/clients/postsServiceClient';
import { format, isPast } from 'date-fns';

// types
import { SocialChannels } from '@/models/posts/socialChannels';
import { DataTableItem } from '@/models/posts/postModal';
import { DraftPost } from '@/models/posts/draftPost';
import { DraftResponse } from '@/models/posts/draftResponse';
import { PostListItem } from '@/models/posts/postListItem';

const postsServiceClient = new PostsServiceClient();

@Module({ namespaced: true, dynamic: true, store, name: 'CreatePostModule' })
export class CreatePostModule extends VuexModule {
  public postTitle: string = '';
  public postBody: string = '';
  public scheduledTime: string | null = null;
  public scheduledDate: string | null = null;
  public isScheduledImmediately: boolean = false;
  public isRecommendedPost: boolean = false;
  public recommendedPostsAvailableChannels: string[] = SocialChannels;
  public msoPostId: guid = '';
  public selectedStoresAndChannels: DataTableItem[] = [];
  public usedTemplateId: guid = '';

  @Mutation
  public setPostTitle(title: string): void {
    this.postTitle = title;
  }

  @Mutation
  public setPostBody(body: string): void {
    this.postBody = body;
  }

  @Mutation
  public setScheduledTime(value: string | null): void {
    this.scheduledTime = value;
  }

  @Mutation
  public setScheduledDate(value: string | null): void {
    this.scheduledDate = value;
  }

  @Mutation
  public setIsScheduledImmediately(flag: boolean): void {
    this.isScheduledImmediately = flag;
  }

  @Mutation
  public setSelectedStoresAndChannels(value: DataTableItem[]): void {
    this.selectedStoresAndChannels = value;
  }

  @Mutation
  public setMsoPostId(msoPostId: guid) {
    this.msoPostId = msoPostId;
  }

  @Mutation
  public setPostTemplate(post: PostListItem): void {
    this.postTitle = post.title;
    this.postBody = post.copy;
    this.isRecommendedPost = post.isRecommended;

    if (post.isRecommended) {
      const suggestedDate = post.suggestedPublishDateTime
        ? new Date(post.suggestedPublishDateTime)
        : new Date();

      this.isScheduledImmediately = isPast(suggestedDate);

      // only use suggested time if in future
      if (!this.isScheduledImmediately) {
        this.scheduledDate = format(suggestedDate, 'yyyy-MM-dd');
        this.scheduledTime = suggestedDate.toLocaleTimeString('en-AU', {
          hour12: false,
        });
      }
      this.usedTemplateId = post.id;
      this.recommendedPostsAvailableChannels = post.availableChannels ?? [];
    }
  }

  @Mutation
  public reset() {
    this.postTitle = '';
    this.postBody = '';
    this.scheduledTime = null;
    this.scheduledDate = null;
    this.isScheduledImmediately = false;
    this.isRecommendedPost = false;
    this.recommendedPostsAvailableChannels = SocialChannels;
    this.msoPostId = '';
    this.selectedStoresAndChannels = [];
    this.usedTemplateId = '';
  }

  @Action({ commit: 'setMsoPostId' })
  async getMsoPostId(): Promise<guid> {
    return await postsServiceClient.getMsoPostId();
  }

  @Action
  public saveDraftPost(draftPost: DraftPost): Promise<DraftResponse> {
    return postsServiceClient.saveDraftPost(draftPost);
  }
}
