import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

export class AppApi {
  /**
   * The user id in the context of which requests are executed.
   * If this property is set to a valid guid, every request made with
   * this client will include the X-User-Id header.
   */
  public requestingUserId: guid | undefined;

  private client: AxiosInstance;

  constructor(baseUrl?: string, requestingUserId?: guid) {
    this.client = this.copyInterceptors(
      axios.create({
        timeout: 30000,
        headers: {
          'Content-Type': 'application/json'
        },
        // With credentials includes cookies/authorization headers
        // in cross-domain requests
        withCredentials: true,
        baseURL: baseUrl
      })
    );
    this.requestingUserId = requestingUserId;
    // this.client.interceptors.response.use((response) => response, (error) => error.response);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async get<TOut, TData = any>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TOut>> {
    config = this.setHeaders(config);
    // @ts-ignore
    return await this.client.get<TOut>(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async post<TOut, TData = any>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TOut>> {
    config = this.setHeaders(config);
    return await this.client.post<TOut>(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async put<TOut, TData = any>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TOut>> {
    config = this.setHeaders(config);
    return await this.client.put<TOut>(url, data, config);
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  protected async patch<TOut, TData = any>(
    url: string,
    data?: TData,
    config?: AxiosRequestConfig
  ): Promise<AxiosResponse<TOut>> {
    config = this.setHeaders(config);
    return await this.client.patch<TOut>(url, data, config);
  }

  protected async delete<TOut>(
    url: string,
    config?: AxiosRequestConfig
  ): Promise<TOut> {
    config = this.setHeaders(config);
    return await this.client.delete(url, config);
  }

  private setHeaders(config?: AxiosRequestConfig): AxiosRequestConfig {
    config = config || { headers: {} };
    if (!config.headers) config.headers = {};

    if (this.requestingUserId) {
      config.headers['X-User-Id'] = this.requestingUserId;
    }

    const tokenData = JSON.parse(localStorage.getItem('tokenData') || '{}');
    const accessToken = tokenData.access_token;
    this.client.defaults.headers.common['Authorization'] = accessToken
      ? `Bearer ${accessToken}`
      : '';

    const brandData = JSON.parse(localStorage.getItem('currentBrand') || '{}');
    const brandKey = brandData.key;
    this.client.defaults.headers.common['Brand'] = brandKey || '';

    return config;
  }

  private copyInterceptors(client: AxiosInstance): AxiosInstance {
    // Copy both request & response interceptors from global axios instance
    client.interceptors.request = axios.interceptors.request;
    client.interceptors.response = axios.interceptors.response;
    return client;
  }

  protected checkRequestingUserSet(): void {
    if (!this.requestingUserId) {
      throw new Error(
        'Requesting user id must be set in order to call this method.'
      );
    }
  }
}
