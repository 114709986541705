// CreateModule is responsible for the create page flow, presets and the stage
// CreateAssetModule is responsible for asset information, asset stores, asset channels etc
// CreatePostModule is responsible for post information, post stores, post channels etc

import Vue from 'vue';
import store from './store';
import { Module, Mutation, Action, VuexModule } from 'vuex-module-decorators';
import { AssetsServiceClient } from '@/api/clients/assetsServiceClient';

// types
import { SocialChannel } from '@/models/posts/socialChannels';
import { CampaignCategory } from '@/models/campaigns/campaignCategory';
import { MsoAssetStoreSummary } from '@/models/assets/assetListItem';

const assetsServiceClient = new AssetsServiceClient();

@Module({ namespaced: true, dynamic: true, store, name: 'CreateAssetModule' })
export class CreateAssetModule extends VuexModule {
  public assetTitle: string = '';
  public channels: SocialChannel[] = [];
  public selectedCategory: CampaignCategory | null = null;
  public msoSelectedStores: MsoAssetStoreSummary[] = [];
  public sharedMsoAssetId: guid = '';
  public storeAssetIdsMap: Map<guid, guid> = new Map();
  public newlyCreatedAssetIds: guid[] = []; // response ids from server

  @Mutation
  public setAssetTitle(title: string): void {
    this.assetTitle = title;
  }

  @Mutation
  public setChannels(value: SocialChannel[]): void {
    this.channels = value;
  }

  @Mutation
  public setSelectedCategory(category: CampaignCategory | null): void {
    this.selectedCategory = category;
  }

  @Mutation
  public setMsoSelectedStores(stores: MsoAssetStoreSummary[]) {
    this.msoSelectedStores = stores;
  }

  @Mutation
  public setSharedMsoAssetId(guid: guid) {
    this.sharedMsoAssetId = guid;
  }

  @Mutation
  public addStoreAssetId(payload: any) {
    this.storeAssetIdsMap.set(payload.storeGuid, payload.assetGuid);
  }

  @Mutation
  public storeNewlyCreatedAssetId(guid: guid) {
    this.newlyCreatedAssetIds.push(guid);
  }

  @Mutation
  public clearNewlyCreatedAssetIds() {
    this.newlyCreatedAssetIds = [];
  }

  @Mutation
  public reset() {
    this.assetTitle = '';
    this.channels = [];
    this.selectedCategory = null;
    this.msoSelectedStores = [];
    this.sharedMsoAssetId = '';
  }

  @Action({ commit: 'setSharedMsoAssetId' })
  async getSharedMsoAssetId(): Promise<guid> {
    return await assetsServiceClient.getSharedMsoAssetId();
  }
}
