import { DummyStoreForAllStores, ALLSTORES } from '@/constants';
import store from './store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { StoreUser } from '@/api/contracts/storeUser';
import { UserServiceClient } from '@/api/clients/userServiceClient';
import { LoginCredentials, TokenResult } from '@/api/contracts/identity';
import { TokenValidationParams } from '@/api/contracts/identity/tokenValidationParams';
import axios from 'axios';
import { UserStore } from '@/models/users/userStore';
import { LocalStorageKeys } from '@/constants';
import { UpdateProfileParams } from '@/api/clients/updateProfileParams';
import { RefreshTokenParams } from '@/api/contracts/identity/refreshTokenParams';

const service = new UserServiceClient();

@Module({ dynamic: true, store, name: 'UserModule' })
export class UserModule extends VuexModule {
  public currentUser: StoreUser | null = null;
  public currentStore: UserStore = new DummyStoreForAllStores();
  public accessToken: string | null = null;
  public refreshToken: string | null = null;
  public tokenExpiresIn: string | null = null;
  public changePasswordModalVisible: boolean = false;

  public get isCurrentStoreInAutomatedProgram(): boolean {
    return !!this.currentStore?.isParticipatingInAutomatedProgram;
  }

  public get isMultiStoreOwner(): boolean {
    if (!this.currentUser) return false;
    return this.currentUser.stores.length > 1;
  }

  public get isViewingSingleStore(): boolean {
    return !this.isMultiStoreOwner || this.currentStore.title !== ALLSTORES;
  }

  @Action({ commit: 'setCurrentUser' })
  public async fetchUser({
    username,
    password
  }: LoginCredentials): Promise<StoreUser> {
    const response = await service.fetchUser(username, password);
    return response.data;
  }

  @Action({ commit: 'setAccessToken' })
  public async login(params: LoginCredentials): Promise<TokenResult> {
    return await service.login(params);
  }

  @Action({ commit: 'setAccessToken' })
  public async refreshJwtToken(
    params: RefreshTokenParams
  ): Promise<TokenResult> {
    return await service.refreshToken(params);
  }

  @Action
  public async updateUserProfile(params: UpdateProfileParams) {
    const response = await service.updateProfile(params);
    const userString = localStorage.getItem('user');

    if (userString) {
      const userData = JSON.parse(userString);

      if (params.displayName) {
        userData.displayName = params.displayName;
      }

      this.setCurrentUser(userData);
      localStorage.setItem(LocalStorageKeys.User, JSON.stringify(userData));
    }

    return response;
  }

  @Action
  public async validateToken(params: TokenValidationParams) {
    return await service.validateToken(params);
  }

  @Mutation
  public async logout(): Promise<void> {
    if (this.currentUser?.isSSO) {
      try {
        await service.ssoLogout();
      } catch (error) {
        console.error(error);
      }
    }
    this.currentUser = null;
    localStorage.clear();
    axios.defaults.headers.common['Authorization'] = null;
  }

  @Mutation
  public setCurrentStore(store: UserStore): void {
    this.currentStore = store;
    localStorage.setItem(LocalStorageKeys.CurrentStore, JSON.stringify(store));
  }

  @Mutation
  public setCurrentUser(user: StoreUser | null): void {
    if (user) {
      this.currentUser = user;
    }
  }

  @Mutation
  public setAccessToken(result: TokenResult): void {
    this.accessToken = result.access_token;
    this.refreshToken = result.refresh_token;
    this.tokenExpiresIn = result.expires_in; // This is in `seconds`
  }

  @Mutation
  public setDisplayName(name: string) {
    if (this.currentUser) this.currentUser.displayName = name;
  }

  @Mutation
  public showChangePasswordModal(showModal: boolean): void {
    this.changePasswordModalVisible = showModal;
  }
}
