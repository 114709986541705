import Vue from 'vue';
import Router from 'vue-router';

// Import module routes.
import publicRoutes from '@/modules/public/routes';
import creativeCentreRoutes from '@/modules/creativeCenter/routes';
import calendarRoutes from '@/modules/calendar/routes';
import assetLibraryRoutes from '@/modules/assetLibrary/routes';
import settingsRoutes from '@/modules/settings/routes';
import helpAndFAQsRoutes from '@/modules/helpAndFaqs/routes';
import homeRoutes from '@/modules/home/routes';
import createRoutes from '@/modules/create/routes';

// Import route guards
import guards from './guards';

import { UserModule } from '@/store/userModule';
import { getModule } from 'vuex-module-decorators';

const userModule = getModule(UserModule);

Vue.use(Router);

const router: Router = new Router({
  mode: 'history',
  routes: [
    ...publicRoutes,
    ...creativeCentreRoutes,
    ...calendarRoutes,
    ...assetLibraryRoutes,
    ...settingsRoutes,
    ...helpAndFAQsRoutes,
    ...homeRoutes,
    ...createRoutes,
  ],
});


router.afterEach(() => {
  if (window.Appcues && window.Appcues.page) {
    window.Appcues.identify(userModule.currentUser?.email, { userName: userModule.currentUser?.displayName });
    window.Appcues.page();
  }
})
class RouteGuardManager {
  public getRouter(): Router {
    return router;
  }

  public registerRouteGuards(): void {
    for (const guard of guards) {
      const beforeEnter = guard.getBeforeEnterGuard();
      if (beforeEnter) {
        router.beforeEach(beforeEnter);
      }
    }
  }
}

export const routeGuardManager = new RouteGuardManager();
export default router;
