import store from './store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { Sticker } from '@/api/contracts/assets/sticker';
import { CategoriesServiceClient } from '@/api/clients/categoriesServiceClient';
import { AssetAudience } from '@/api/contracts/assets/assetAudience';
import { AssetProductType } from '@/api/contracts/assets/assetProductType';

const getDefaultState = () => {
  return {
    currentStep: 1,
    files: [],
    image: null,
    sticker: null,
    stickerPosition: { x: 0, y: 0 }
  };
};

const getDefaultMainImage = () => {
  return {
    mainImagePosition: {
      x: 0,
      y: 0
    },
    mainImageTransforms: {
      x: 0,
      y: 0,
      rotation: 0,
      scaleX: 1,
      scaleY: 1
    }
  };
};

const getDefaultSticker = () => {
  return {
    stickerPosition: {
      x: 0,
      y: 0
    },
    stickerTransforms: {
      x: 0,
      y: 0,
      rotation: 0,
      scaleX: 1,
      scaleY: 1
    }
  };
};

const categoriesServiceClient = new CategoriesServiceClient();

@Module({ dynamic: true, store, name: 'NewAssetModalModule' })
export class NewAssetModalModule extends VuexModule {
  public files: File[] = [];
  public visible: boolean = false;
  public image: HTMLImageElement | null = null;
  public mainImagePosition: object = {
    x: 0,
    y: 0
  };
  public mainImageTransforms: object = {
    x: 0,
    y: 0,
    rotation: 0,
    scaleX: 1,
    scaleY: 1
  };
  public selectedSticker: Sticker | null = null;
  public sticker: HTMLImageElement | null = null;
  public stickerPosition: object = {
    x: 0,
    y: 0
  };
  public stickerTransforms: object = {
    x: 0,
    y: 0,
    rotation: 0,
    scaleX: 1,
    scaleY: 1
  };
  public currentStep: number = 1;
  public audiences: AssetAudience[] = [];
  public productTypes: AssetProductType[] = [];

  @Action({ commit: 'setAudiences' })
  async getAudiences(): Promise<AssetAudience[]> {
    const response = await categoriesServiceClient.getAudiences();
    return response.data;
  }

  @Action({ commit: 'setProductTypes' })
  async getProductTypes(): Promise<AssetProductType[]> {
    const response = await categoriesServiceClient.getProductTypes();
    return response.data;
  }

  @Mutation
  public setAudiences(audiences: AssetAudience[]) {
    this.audiences = audiences;
  }

  @Mutation
  public setProductTypes(productTypes: AssetProductType[]) {
    this.productTypes = productTypes;
  }

  @Mutation
  public setCurrentStep(step: number) {
    this.currentStep = step;
  }

  @Mutation
  public setMainImagePosition(position: object) {
    this.mainImagePosition = position;
  }

  @Mutation
  public setMainImageTransforms(transforms: object) {
    this.mainImageTransforms = transforms;
  }

  @Mutation
  public setNewAssetModalVisible(visible: boolean): void {
    this.visible = visible;
  }

  @Mutation
  public setSelectedImage(image: HTMLImageElement | null): void {
    this.image = image;
  }

  @Mutation
  public setSelectedSticker(sticker: Sticker | null): void {
    this.selectedSticker = sticker;
  }

  @Mutation
  public setSticker(sticker: HTMLImageElement | null): void {
    this.sticker = sticker;
  }

  @Mutation
  public setStickerPosition(position: object) {
    this.stickerPosition = position;
  }

  @Mutation
  public setStickerTransforms(transforms: object) {
    this.stickerTransforms = transforms;
  }

  @Mutation
  resetState() {
    Object.assign(this, getDefaultState());
  }

  @Mutation
  resetMainImage() {
    Object.assign(this, getDefaultMainImage());
  }

  @Mutation
  resetSticker() {
    this.sticker = null;
    this.selectedSticker = null;
    Object.assign(this, getDefaultSticker());
  }
}
