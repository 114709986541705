import { RouteConfig } from 'vue-router';
import { RouteMetaDefinition } from '@/router/meta';

// Meta data is an object that defines a route, you can check against meta data in RouteGuards.
const unAuthedMeta: RouteMetaDefinition = {
  auth: false,
  layout: () => import('@/layouts/publicLayout.vue')
};

const routes: RouteConfig[] = [
  {
    path: '/',
    meta: unAuthedMeta,
    components: {
      default: () => import('./module.vue')
    },
    children: [
      {
        name: 'public.login',
        path: '',
        meta: unAuthedMeta,
        component: () => import('./views/index.vue')
      },
      {
        name: 'public.register',
        path: 'register',
        meta: unAuthedMeta,
        component: () => import('./views/register.vue')
      },
      {
        name: 'public.forgot-password',
        path: 'forgot-password',
        meta: unAuthedMeta,
        component: () => import('./views/forgotPassword.vue')
      },
      {
        name: 'public.reset-password',
        path: 'reset-password',
        meta: unAuthedMeta,
        component: () => import('./views/resetPassword.vue')
      },
      {
        name: 'public.validate-email',
        path: 'validate-email',
        meta: unAuthedMeta,
        component: () => import('./views/validateEmail.vue')
      },
      {
        name: 'public.sso',
        path: 'loggedin',
        meta: unAuthedMeta,
        component: () => import('./views/sso.vue')
      }
    ]
  }
];

export default routes;
