import axios from 'axios';
import { UserModule } from '@/store';
import { getModule } from 'vuex-module-decorators';
import router from '@/router';
import RefreshTokenService from './refreshTokenService';

class ErrorsInterceptor {
  // private uiModule = getModule(UiModule); // save for future ui comms
  private userModule = getModule(UserModule);

  public logout(): void {
    this.userModule.logout();
    RefreshTokenService.stopRefreshTokenTimeout();
  }
}

new ErrorsInterceptor();

axios.interceptors.response.use(
  response => response, // return any successful response
  async error => {
    switch (error.response.status) {
      case (401): {

        // store articleId as query param for redirect after login
        const queryParams: any = {};
        if (error.config.url.includes('api/articles/')) {
          const articleId = error.config.url.split('api/articles/')[1];
          queryParams.article = articleId;
        }

        // error on 'auth/token' might be from a refreshTokenService 401 or;
        // user has incorrectly entered their login details and if any redirect params exist they will be lost. So we add them back to the query string
        if (error.config.url.includes('auth/token')) {
          const articleId = router.currentRoute.query?.article;
          if (articleId) queryParams.article = articleId;
        }

        await router.push({ name: 'public.login', query: queryParams });
        break;
      }
      case (500):
        // TODO: add global notification module
        return Promise.reject(error.response);

      default: {
        console.error('Uncaught error response');
        return Promise.reject(error.response);
      }
    }
  }
);
