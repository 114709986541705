import store from './store';
import { Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AltDrawerType } from '@/constants';

@Module({ dynamic: true, store, name: 'UiModule' })
export class UiModule extends VuexModule {
  public mainNavigationDrawerOpen: boolean = false;
  public campaignSidebarOpen: boolean = false;
  public upcomingPostsSidebarOpen: boolean = false;
  public isLoading: boolean = false;

  @Mutation
  public setLoading(showState: boolean): void {
    this.isLoading = showState;
  }

  @Mutation
  public setNavigationDrawer(openState: boolean): void {
    this.mainNavigationDrawerOpen = openState;
  }

  @Mutation
  public toggleAltDrawer(params: {
    drawerType: AltDrawerType;
    open: boolean;
  }): void {
    switch (params.drawerType) {
      case AltDrawerType.UpcomingPostsSidebar: {
        this.upcomingPostsSidebarOpen = params.open;
        break;
      }
      case AltDrawerType.CampaignSidebar: {
        this.campaignSidebarOpen = params.open;
        break;
      }
    }
  }
}
