import store from './store';
import { Action, Module, Mutation, VuexModule } from 'vuex-module-decorators';
import { AssetsServiceClient } from '@/api/clients/assetsServiceClient';
import { PagedAssetListResponse } from '@/api/contracts/assets';
import { AssetListRequest } from '@/models/assets/assetListRequest';
import { AssetsFilters } from '@/models/assets/assetsFilters';

const client = new AssetsServiceClient();

@Module({
  namespaced: true,
  name: 'AssetModule',
  store,
  dynamic: true
})
export class AssetModule extends VuexModule {
  public storeAssetsResponse: PagedAssetListResponse = {
    pagination: { total: 0, limit: 0, start: 0, sort: '' },
    data: []
  };
  public assetsFilters: AssetsFilters = {
    category: { id: '', title: '', description: '' },
    sortKey: 'DatePublishedDescending'
  };
  public selectedGroupFilter: string = 'national';

  @Action({ commit: 'setStoreAssetsResponse' })
  async getAssets(params: AssetListRequest): Promise<PagedAssetListResponse> {
    return await client.getAssets(params);
  }

  @Mutation
  setSelectedGroupFilter(newGroupFilter: string): void {
    this.selectedGroupFilter = newGroupFilter;
  }

  @Mutation
  setStoreAssetsResponse(pagedAssetList: PagedAssetListResponse): void {
    this.storeAssetsResponse = Object.assign(
      {},
      this.storeAssetsResponse,
      pagedAssetList
    );
  }

  @Mutation
  setCurrentFilter(assetsFilters: AssetsFilters): void {
    const { category, ...restFilters } = assetsFilters;
    this.assetsFilters.category.id = category.id;
    this.assetsFilters.category.title = category.title;
    this.assetsFilters = { ...this.assetsFilters, ...restFilters };
  }
}
