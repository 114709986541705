import { RouteGuardDefinition } from './routeGuardDefinition';
import { NavigationGuard, Route, NavigationGuardNext } from 'vue-router';

export class AuthenticatedUserGuard implements RouteGuardDefinition {
  public getBeforeEnterGuard(): NavigationGuard {
    return this.beforeEnter.bind(this);
  }

  private async beforeEnter(
    to: Route,
    from: Route,
    next: NavigationGuardNext
  ): Promise<void> {
    if (this.requiresAuth(to)) {
      // Note: the auth check should be here. Instead a axios interceptor @/services/error.ts redirects to login on any 401: unauthorised
      next();
    } else {
      next();
    }
  }

  private requiresAuth(route: Route): boolean {
    if (route.meta) {
      if (route.meta.auth) {
        return route.meta.auth;
      }
    }
    return false;
  }
}
