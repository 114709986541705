
import Vue, { Component as VueComponent } from 'vue';
import { Component } from 'vue-property-decorator';
import emptyLayout from '@/layouts/emptyLayout.vue';
import { StoresModule } from '@/store/storesModule';
import { getModule } from 'vuex-module-decorators';
import { BrandName } from './models/stores/brands';

const storesModule = getModule(StoresModule);

@Component
export default class App extends Vue {
  public get layout(): VueComponent {
    if (!this.$route.meta?.layout) {
      return emptyLayout;
    }
    return this.$route.meta.layout;
  }

  public get currentBrandName(): string {
    switch (storesModule.currentBrand.name) {
      case BrandName.LOCAL:
        return 'local';
      case BrandName.SUPA:
        return 'supa';
      default:
        return 'iga';
    }
  }

  public get currentBreakpoint(): string {
    return this.$vuetify.breakpoint.name;
  }

  public get hasTouch() {
    return (
      'ontouchstart' in window ||
      navigator.maxTouchPoints > 0 ||
      navigator.msMaxTouchPoints > 0
    );
  }
}
