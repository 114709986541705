import { PostAndCampaignsData } from '@/api/contracts/calendar/calendarEventsResponse';
import { AppApi } from '@/api/appApi';
import { PostAndCampaignsResponse } from '@/api/contracts/calendar/calendarEventsResponse';
import { UserModule } from '@/store/userModule';
import { StoresModule } from '@/store/storesModule';
import { getModule } from 'vuex-module-decorators';

const userModule = getModule(UserModule);
const storesModule = getModule(StoresModule);

export class CalendarServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async getPostsAndCampaigns(
    fromDate?: datestring,
    toDate?: datestring
  ): Promise<PostAndCampaignsData> {
    const query = userModule.isViewingSingleStore
      ? `storeId=${userModule.currentStore.id}&fromDate=${fromDate}&toDate=${toDate}`
      : `fromDate=${fromDate}&toDate=${toDate}`;

    const response = await this.get<PostAndCampaignsResponse>(
      `/api/mso/calendar/${storesModule.currentRegion}?${query}`
    );

    return response.data.data as PostAndCampaignsData;
  }
}
