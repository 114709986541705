import { AppApi } from '@/api/appApi';
import { GetAudiencesResponse } from '@/api/contracts/categories/getAudiencesResponse';
import { GetProductTypesResponse } from '@/api/contracts/categories/getProductTypesResponse';
import { GetArticleCategoriesResponse } from '@/api/contracts/categories/GetArticleCategoriesResponse';

export class CategoriesServiceClient extends AppApi {
  constructor() {
    super(`${process.env.VUE_APP_API_ENDPOINT_PROXY}/`);
  }

  public async getAudiences(): Promise<GetAudiencesResponse> {
    const response = await this.get<GetAudiencesResponse>(
      `api/categories/audiences`
    );

    return response.data as GetAudiencesResponse;
  }

  public async getProductTypes(): Promise<GetProductTypesResponse> {
    const response = await this.get<GetProductTypesResponse>(
      `api/categories/products`
    );
    return response.data as GetProductTypesResponse;
  }

  public async getArticleCategories(): Promise<GetArticleCategoriesResponse> {
    const response = await this.get<GetArticleCategoriesResponse>(
      `api/categories/articles`
    );
    return response.data as GetArticleCategoriesResponse;
  }
}
