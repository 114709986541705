import { AssetListItem } from '@/models/assets/assetListItem';

interface CategoriesEntity {
  title: string;
  assets?: AssetListItem[] | null;
  id: string;
}
export interface QuickLinksCardsEntity {
  url: string;
  blobUrl: string;
  description: string;
  id: guid;
  title: string;
}

interface Data {
  categories?: CategoriesEntity[] | null;
  cards?: QuickLinksCardsEntity[] | null;
}

export class DashboardResponse {
  data?: Data;
}
